.talent {
  cursor: pointer;
  border: 1px solid black;
  margin-bottom: 5px;
}

.talent:hover {
  background-color: #f5f5f5; /* Adjust hover color as needed */
}

.selected {
  background-color: #ececec; /* Adjust selected color as needed */
  cursor: pointer;
}

.selected:hover {
  background-color: #e0e0e0; /* Adjust hover color for selected items as needed */
}
